import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ComplienceComponent} from './complience.component';
import {RouterModule} from '@angular/router';
import {complienceRoutes} from './complience-routing.module';
import {TranslateModule} from '@ngx-translate/core';


@NgModule({
  declarations: [ComplienceComponent],
  exports: [ComplienceComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(complienceRoutes),
    TranslateModule
  ]
})
export class ComplienceModule {
}
