<header>
  <section class="section-2">
    <a class="logo"><img src="../../../assets/img/logo.png" alt=""></a>
  </section>

  <nav class="nav-bar">
    <div id="toggle-btn">
      <label for="toggle">
        <i class="fas fa-bars"></i>
      </label>
    </div>
    <input type="checkbox" id="toggle">
    <ul>
      <li class="nav-list-items"><a (click)="route('Home')" translate>navbar.home</a></li>
      <li class="nav-list-items"><a (click)="route('Activities')" translate>navbar.activities</a></li>
      <li class="nav-list-items"><a (click)="route('Compliance')" translate>navbar.compliance</a></li>
      <li class="nav-list-items"><a (click)="route('Information-Community-Center')" translate>navbar.infoSociety</a>
      </li>
      <li><a (click)="route('Contact')" translate>navbar.contact</a></li>
      <li>
        <a (click)="changeLang()">
          <img class="flag" src="assets/img/tr-flag.png">
          <img class="flag" src="assets/img/eng-flag.png">
        </a>
      </li>
    </ul>
  </nav>
</header>
