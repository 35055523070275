<div class="container">
  <p style="color:black; " translate>compliance.mainText</p>

  <p>
    <em>
      <a href="../../../assets/pdf/musteri-kabul-politikasi.pdf" target="_blank">
      <span style="color:#9d8b54" translate>compliance.customer
      </span>
      </a>
    </em>
  </p>
  <p>
    <em>
      <a href="../../../assets/pdf/responsible-gold-guidance.pdf" target="_blank">
      <span style="color:#9d8b54" translate>compliance.lbma</span>
      </a>
    </em>
  </p>
  <p>
    <em>
      <a href="../../../assets/pdf/GoldSupplement.pdf" target="_blank">
        <span style="color:#9d8b54" translate>compliance.oecd</span>
      </a>
    </em>
  </p>
  <p>
    <em>
      <a href="../../../assets/pdf/Conflict_Free_Gold_Standard_English.pdf" target="_blank">
        <span style="color:#9d8b54" translate>compliance.word</span>
      </a>
    </em>
  </p>
  <p>
    <em>
      <a href="KYC-FORM.pdf" target="_blank">
        <span style="color:#9d8b54" translate>compliance.kyc</span>
      </a>
    </em>
  </p>
  <p>
    <a href="../../../assets/pdf/CerceveSozlesme.pdf" target="blank">
      <em>
        <a href="../../../assets/pdf/ÇerçeveSözleşme.pdf" target="_blank">
          <span style="color:#9d8b54" translate>compliance.auth</span>
        </a>
      </em>
    </a>
  </p>
   <p *ngIf="lang === 'tr'">
    <em>
      <a href="../../../assets/pdf/EK1BEYAN.pdf" target="_blank">
        <span style="color:#9d8b54" translate>compliance.declaration</span>
      </a>
    </em>
  </p>
  <p *ngIf="lang === 'eng'">
    <em>
      <a href="../../../assets/pdf/EK1BEYAN.pdf" target="_blank">
        <span style="color:#9d8b54" translate>compliance.declaration</span>
      </a>
    </em>
  </p>
  <p>
    <em>
      <a href="../../../assets/pdf/EK2MusteriTaniFormu.pdf" target="_blank">
        <span style="color:#9d8b54" translate>compliance.id</span>
      </a>
    </em>
  </p>
  <p>
    <em>
      <a href="../../../assets/pdf/EK3MusteriTaniFormu.pdf" target="_blank">
        <span style="color:#9d8b54" translate>compliance.tree</span>
      </a>
    </em>
  </p>
  <p>
    <em>
      <a href="../../../assets/pdf/EK4GerekenEvraklar.pdf" target="_blank">
        <span style="color:#9d8b54" translate>compliance.requied</span>
      </a>
    </em>
  </p>
  <!-- buradan sonra 2 pdf eklendi -->
  <p>
    <em>
      <a href="../../../assets/pdf/ickontrolsistemi.pdf" target="_blank">
        <span style="color:#9d8b54" translate>compliance.kontrol</span>
      </a>
    </em>
  </p>
  <p>
    <em>
      <a href="../../../assets/pdf/AydinlatmaMetni.pdf" target="_blank">
        <span style="color:#9d8b54" translate>compliance.Illumination</span>
      </a>
    </em>
  </p>
  <p>
    <em>
      <a href="../../../assets/pdf/CerezAydinlatmaMetni.pdf" target="_blank">
        <span style="color:#9d8b54" translate>compliance.cookies</span>
      </a>
    </em>
  </p>
  <p>
    <em>
      <a href="../../../assets/pdf/VeriSahibiBasvuruFormu.pdf" target="_blank">
        <span style="color:#9d8b54" translate>compliance.dataowner</span>
      </a>
    </em>
  </p>
  <p *ngIf="lang === 'tr'">
    <em>
      <a href="../../../assets/pdf/2022guvence.pdf" target="_blank">
        <span style="color:#9d8b54" translate>compliance.guvence</span>
      </a>
    </em>
  </p>
  <p *ngIf="lang === 'eng'">
    <em>
      <a href="../../../assets/pdf/2022guvence.pdf" target="_blank">
        <span style="color:#9d8b54" translate>compliance.guvence</span>
      </a>
    </em>
  </p>

  <p *ngIf="lang === 'tr'">
    <em>
      <a href="../../../assets/pdf/zirvedenetim.pdf" target="_blank">
        <span style="color:#9d8b54" translate>compliance.denetim</span>
      </a>
    </em>
  </p>
  <p *ngIf="lang === 'eng'">
    <em>
      <a href="../../../assets/pdf/zirvedenetim.pdf" target="_blank">
        <span style="color:#9d8b54" translate>compliance.denetim</span>
      </a>
    </em>
  </p>
  <p *ngIf="lang === 'tr'">
    <em>
      <a href="../../../assets/pdf/2023yonetim.pdf" target="_blank">
        <span style="color:#9d8b54" translate>compliance.yonetim</span>
      </a>
    </em>
  </p>
  <p *ngIf="lang === 'eng'">
    <em>
      <a href="../../../assets/pdf/2023yonetim.pdf" target="_blank">
        <span style="color:#9d8b54" translate>compliance.yonetim</span>
      </a>
    </em>
  </p>
  <p *ngIf="lang === 'tr'">
    <em>
      <a href="../../../assets/pdf/2023denetim.pdf" target="_blank">
        <span style="color:#9d8b54" translate>compliance.2023denetim</span>
      </a>
    </em>
  </p>
  <p *ngIf="lang === 'eng'">
    <em>
      <a href="../../../assets/pdf/2023denetim.pdf" target="_blank">
        <span style="color:#9d8b54" translate>compliance.2023denetim</span>
      </a>
    </em>
  </p>
  <p *ngIf="lang === 'tr'">
    <em>
      <a href="../../../assets/pdf/2023guvence.pdf" target="_blank">
        <span style="color:#9d8b54" translate>compliance.2023guvence</span>
      </a>
    </em>
  </p>
  <p *ngIf="lang === 'eng'">
    <em>
      <a href="../../../assets/pdf/2023guvence.pdf" target="_blank">
        <span style="color:#9d8b54" translate>compliance.2023guvence</span>
      </a>
    </em>
  </p>
 <!-- buradan sonra 2 pdf eklendi -->
  <br><br><br><br><br><br>
</div>
