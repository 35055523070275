import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateServices} from '../../services/translate.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  lang = 'tr';

  constructor(private router: Router,
              private translateServices: TranslateServices) {
  }

  ngOnInit(): void {
  }

  closeNav(): void {
    document.getElementById('mySidenav').style.width = '0';
  }

  openNav(): void {
    document.getElementById('mySidenav').style.width = '250px';
  }

  route(page) {
    this.router.navigateByUrl(page);
  }

  changeLang() {
    if (this.lang === 'tr') {
      this.lang = 'eng';
    } else {
      this.lang = 'tr';
    }
    this.translateServices.changeLanguage(this.lang);
  }
}
