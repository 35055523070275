<div class="container">
  <table class="tg" style="undefined;table-layout: fixed; width: 100%">
    <colgroup>
      <col style="width: 100px">
      <col style="width: 100%">
    </colgroup>
    <thead>
    <tr>
      <th class="tg-1wig" translate>infoSociety.companyType</th>
      <th class="tg-6t3r">A.Ş.</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td class="tg-1wig" translate>infoSociety.registrationNo</td>
      <td class="tg-6t3r">9980832755</td>
    </tr>
    <tr>
      <td class="tg-1wig" translate>infoSociety.commercialTitle</td>
      <td class="tg-6t3r">Zirve Değerli Madenler Tic. A.Ş</td>
    </tr>
    <tr>
      <td class="tg-1wig" translate>infoSociety.address</td>
      <td class="tg-6t3r">Taya Hatun Mah. Ayrancılar Sok. Sıra Odalar Han No: 26 İç Kapı No:103 Fatih,İstanbul/TURKEY
      </td>
    </tr>
    <tr>
      <td class="tg-1wig" colspan="2" translate>infoSociety.contact</td>
    </tr>
    <tr>
      <td class="tg-1wig" translate>infoSociety.phone</td>
      <td class="tg-6t3r">+90 212 512 71 33</td>
    </tr>
    <tr>
      <td class="tg-1wig" translate>infoSociety.mail</td>
      <td class="tg-6t3r">info@zirvedegerlimadenler.com</td>
    </tr>
    <tr>
      <td class="tg-1wig" translate>infoSociety.dateOfRegistration</td>
      <td class="tg-6t3r">08/09/2016</td>
    </tr>
    <tr>
      <td class="tg-1wig" translate>infoSociety.sector</td>
      <td class="tg-6t3r" translate>infoSociety.sectorText</td>
    </tr>
    <tr>
      <td class="tg-1wig" translate>infoSociety.chairman</td>
      <td class="tg-6t3r">Fatih ÖZDAMAR</td>
    </tr>
    </tbody>
  </table>
</div>
