import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ActivitiesComponent} from './activities.component';
import {RouterModule} from '@angular/router';
import {activitiesRoutes} from './activities-routing.module';
import {TranslateModule} from '@ngx-translate/core';


@NgModule({
  declarations: [ActivitiesComponent],
  exports: [ActivitiesComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(activitiesRoutes),
    TranslateModule
  ]
})
export class ActivitiesModule {
}
