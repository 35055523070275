import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ROUTING} from './models/routing';

const routes: Routes = [
  {
    path: ROUTING.HOME,
    loadChildren: () => import('./pages/home/home.module').then((m) => m.HomeModule)
  }, {
    path: ROUTING.CONTACT,
    loadChildren: () => import('./pages/contact/contact.module').then((m) => m.ContactModule)
  }, {
    path: ROUTING.ABOUT,
    loadChildren: () => import('./pages/about/about.module').then((m) => m.AboutModule)
  }, {
    path: ROUTING.COMPLIENCE,
    loadChildren: () => import('./pages/complience/complience.module').then((m) => m.ComplienceModule)
  }, {
    path: ROUTING.ACTIVITIES,
    loadChildren: () => import('./pages/activities/activities.module').then((m) => m.ActivitiesModule)
  },
  // otherwise redirect to home
  {
    path: '**',
    redirectTo: ROUTING.HOME
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
