import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AboutComponent} from './about.component';
import {RouterModule} from '@angular/router';
import {aboutRoutes} from './about-routing.module';
import {TranslateModule} from '@ngx-translate/core';


@NgModule({
  declarations: [AboutComponent],
  exports: [AboutComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(aboutRoutes),
    TranslateModule
  ]
})
export class AboutModule {
}
