<section>
  <div class="container">
    <div class="contactInfo">
      <div>
        <h2 translate>contact.contactUs</h2>
        <ul class="info">
          <li>
            <span><img src="../../../assets/img/location.png" alt="location"></span>
            <span>Molla Fenari Mah. Çuhacı Hanı Sok. No:6 Fatih İstanbul</span>
          </li>
          <li>
            <span><img src="../../../assets/img/mail.png"></span>
            <span>info@zirvedegerlimadenler.com</span>
          </li>
          <li>
            <span><img src="../../../assets/img/call.png"></span>
            <span>+90 212 511 55 12</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="contactMap">
      <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d96338.82134274676!2d28.9701887!3d41.0123864!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab929b6ed7d2d%3A0xf49cd4926d9ed706!2zWsSwUlZFIERFxJ5FUkzEsCBNQURFTkxFUiBUxLBDQVJFVCBBTk9OxLBNIMWexLBSS0VUxLA!5e0!3m2!1str!2str!4v1643958263879!5m2!1str!2str" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
    </div>
  </div>
</section>
