<div class="content">
  <section class="who">
    <div class="col-lg-4 col-md-4" id="logo">
      <img style="width:110px" src="../../assets/img/logo2.png">
    </div>
    <div class="col-lg-4 col-md-4 col-sm-6" id="address">
      <h5 translate>footer.address</h5>
      <p>Molla Fenari Mah. Çuhacı Hanı Sok. No:6 Fatih İstanbul
      </p>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-6" id="phone">
      <h5 translate>footer.phone</h5>
      <p> +90 0212 511 55 12</p>
    </div>
    <div style="text-align: center;color: white" class="col-md-12">
      Powered by <a style="color: #f1e2b3" href="http://www.aifasoft.com/">Aifasoft</a>
    </div>
  </section>
</div>
