import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HomeComponent} from './home.component';
import {RouterModule} from '@angular/router';
import {homeRoutes} from './home-routing.module';
import {TranslateModule} from '@ngx-translate/core';


@NgModule({
  declarations: [HomeComponent],
  exports: [HomeComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(homeRoutes),
    TranslateModule
  ]
})
export class HomeModule {
}
